import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { TopComponent } from './components/shared/top/top.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { VillesComponent } from './pages/villes/villes.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatDialogModule } from '@angular/material/dialog';
import { TrackingUsersComponent } from './pages/tracking-users/tracking-users/tracking-users.component';
import { SacsComponent } from './pages/sacs/sacs/sacs.component';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { AddSacComponent } from './pages/sacs/add-sac/add-sac.component';
//import { AddTrackingUserComponent } from './pages/tracking-users/add-tracking-user/add-tracking-user.component';
import { AddClientComponent } from './pages/clients/add-client/add-client.component';
import { AvatarModule } from 'ngx-avatar';
import { DetailSacComponent } from './pages/sacs/detail-sac/detail-sac.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TrackingPreparersComponent } from './pages/tracking-users/tracking-preparers/tracking-preparers.component';
import { CommandesClientComponent } from './pages/clients/commandes-client/commandes-client.component';
import { DetailCommandeClientComponent } from './pages/clients/detail-commande-client/detail-commande-client.component';
import { TrackingLouagistesComponent } from './pages/tracking-users/tracking-louagistes/tracking-louagistes.component';
import { FraisClientComponent } from './pages/clients/frais-client/frais-client.component';
import { EditFraisClientComponent } from './pages/clients/edit-frais-client/edit-frais-client.component';
import { CommandesTrackingUserComponent } from './pages/tracking-users/commandes-tracking-user/commandes-tracking-user.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { CompteClientComponent } from './pages/clients/compte-client/compte-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { ReturnedCommandesComponent } from './pages/dashboard/returned-commandes/returned-commandes.component';
import { BonAchatComponent } from './pages/villes/bon-achat/bon-achat.component';
import { UsersTravelersComponent } from './pages/travelers/users-travelers/users-travelers.component';
import { ReservationsComponent } from './pages/travelers/reservations/reservations.component';
import { OneReservationComponent } from './pages/travelers/one-reservation/one-reservation.component';
import { DetailsLouagisteComponent } from './pages/tracking-users/details-louagiste/details-louagiste.component';
import { BonAchatSacComponent } from './pages/sacs/bon-achat-sac/bon-achat-sac.component';
import { AffectationDialogComponent } from './components/shared/dialogs/affectation-dialog/affectation-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AllCommandesComponent } from './pages/clients/all-commandes/all-commandes.component';
import { FactureCmdComponent } from './pages/clients/facture-cmd/facture-cmd.component';
import { ListCommentsComponent } from './components/shared/dialogs/list-comments/list-comments.component';
import { StatisticsCommandesComponent } from './pages/clients/statistics-commandes/statistics-commandes.component';
import { StatisticsTransportorComponent } from './pages/tracking-users/statistics-transportor/statistics-transportor.component';
import { PickupCollectorComponent } from './components/shared/dialogs/pickup-collector/pickup-collector.component';
import { DemandeClientComponent } from './pages/clients/demande-client/demande-client.component';
import { FacturePaiementClientComponent } from './pages/factures/facture-paiement-client/facture-paiement-client.component';
import { BonPaiementDeliveryComponent } from './pages/factures/bon-paiement-delivery/bon-paiement-delivery.component';
import { DetailVirementsComponent } from './pages/statistics/detail-virements/detail-virements.component';
import { VirementsClientsComponent } from './pages/statistics/virements-clients/virements-clients.component';
import { DetailsVirementComponent } from './components/shared/dialogs/details-virement/details-virement.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgRedux, DevToolsExtension } from '@angular-redux/store';

import { NgReduxModule } from '@angular-redux/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { ListTransportorsComponent } from './pages/transportors/list-transportors/list-transportors.component';
import { DetailsTransportorComponent } from './pages/transportors/details-transportor/details-transportor.component';
import { PositionsTransportorsComponent } from './pages/transportors/positions-transportors/positions-transportors.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DemandesTransportorsComponent } from './pages/transportors/demandes-transportors/demandes-transportors.component';
import { DetailsDemandeComponent } from './pages/transportors/details-demande/details-demande.component';
import { SocietiesTransportorsComponent } from './pages/transportors-societies/societies-transportors/societies-transportors.component';
import { AddSocietyComponent } from './pages/transportors-societies/add-society/add-society.component';
import { CommercialTransportorsComponent } from './pages/transportors/commercial-transportors/commercial-transportors.component';
import { AddCommercialComponent } from './pages/transportors/add-commercial/add-commercial.component';
import { InvalidReturnComponent } from './pages/transportors/invalid-return/invalid-return.component';
import { ValidReturnComponent } from './pages/transportors/valid-return/valid-return.component';
import { ImageViewerComponent } from './components/shared/dialogs/image-viewer/image-viewer.component';
import { ParcTransportorsComponent } from './pages/transportors/parc-transportors/parc-transportors.component';
import { ListBusinessAccountsComponent } from './pages/business-accounts/list-business-accounts/list-business-accounts.component';
import { AddBusinessAccountsComponent } from './pages/business-accounts/add-business-accounts/add-business-accounts.component';
import { SponsoresComponent } from './pages/transportors/sponsores/sponsores.component';
import { PendingDemandesComponent } from './pages/transportors/pending-demandes/pending-demandes.component';
import { PlanifiedDemandesComponent } from './pages/transportors/planified-demandes/planified-demandes.component';
import { AddCommentsComponent } from './components/shared/dialogs/add-comments/add-comments.component';
import { ClientsWassaliComponent } from './pages/transportors/clients-wassali/clients-wassali.component';
import { CompteTransportorComponent } from './pages/transportors/compte-transportor/compte-transportor.component';
import { DisplayDemandesComponent } from './components/shared/dialogs/display-demandes/display-demandes.component';
import { DetailsSocietyComponent } from './pages/transportors-societies/details-society/details-society.component';
import { ContractSocietyComponent } from './pages/transportors-societies/contract-society/contract-society.component';
import { EditProviderComponent } from './pages/clients/edit-provider/edit-provider.component';
import { SendMailsComponent } from './components/shared/dialogs/send-mails/send-mails.component';
import { AllProductsComponent } from './pages/settings/products/all-products/all-products.component';
import { AllPalletsComponent } from './pages/settings/pallets/all-pallets/all-pallets.component';
import { ImportBoxesProductsComponent } from './pages/settings/products/windows/import-boxes-products/import-boxes-products.component';
import { AddProviderProductsComponent } from './components/shared/dialogs/add-provider-products/add-provider-products.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ImportProvidersComponent } from './pages/clients/windows/import-providers/import-providers.component';
import { EditTransportorSocietyComponent } from './pages/transportors-societies/edit-transportor-society/edit-transportor-society.component';
import { AddPalletComponent } from './pages/settings/pallets/windows/add-pallet/add-pallet.component';
import { AddBoxComponent } from './pages/settings/products/windows/add-box/add-box.component';
import { AddCommandeComponent } from './pages/clients/add-commande/add-commande.component';
import { AddBoxByProviderComponent } from './pages/settings/products/windows/add-box-by-provider/add-box-by-provider.component';
import { EditCommandeComponent } from './pages/clients/edit-commande/edit-commande.component';
import { AddTeamComponent } from './pages/teams/add-team/add-team.component';
import { TeamsListComponent } from './pages/teams/teams-list/teams-list.component';
import { SocietiesListCofComponent } from './pages/societies/societies-list-cof/societies-list-cof.component';
import { AddSocietyCofComponent } from './pages/societies/add-society-cof/add-society-cof.component';
import { EditTeamComponent } from './pages/teams/edit-team/edit-team.component';
import { EditSocietyCofComponent } from './pages/societies/edit-society-cof/edit-society-cof.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
    },
    vertical: {
      position: 'top',
    },
  },
  theme: 'material',
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    TopComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    VillesComponent,
    TrackingUsersComponent,
    SacsComponent,
    ClientsComponent,
    AddSacComponent,
    //AddTrackingUserComponent,
    AddClientComponent,
    DetailSacComponent,
    TrackingPreparersComponent,
    CommandesClientComponent,
    DetailCommandeClientComponent,
    TrackingLouagistesComponent,
    FraisClientComponent,
    EditFraisClientComponent,
    CommandesTrackingUserComponent,
    RechercheComponent,
    CompteClientComponent,
    StatusCommandesComponent,
    ReturnedCommandesComponent,
    BonAchatComponent,
    UsersTravelersComponent,
    ReservationsComponent,
    OneReservationComponent,
    DetailsLouagisteComponent,
    BonAchatSacComponent,
    AffectationDialogComponent,
    AllCommandesComponent,
    FactureCmdComponent,
    ListCommentsComponent,
    StatisticsCommandesComponent,
    StatisticsTransportorComponent,
    PickupCollectorComponent,
    DemandeClientComponent,
    FacturePaiementClientComponent,
    BonPaiementDeliveryComponent,
    DetailVirementsComponent,
    VirementsClientsComponent,
    DetailsVirementComponent,
    SidebarComponent,
    BaseLayoutComponent,
    ListTransportorsComponent,
    DetailsTransportorComponent,
    PositionsTransportorsComponent,
    DemandesTransportorsComponent,
    DetailsDemandeComponent,
    SocietiesTransportorsComponent,
    AddSocietyComponent,
    CommercialTransportorsComponent,
    AddCommercialComponent,
    InvalidReturnComponent,
    ValidReturnComponent,
    ImageViewerComponent,
    ParcTransportorsComponent,
    ListBusinessAccountsComponent,
    AddBusinessAccountsComponent,
    SponsoresComponent,
    PendingDemandesComponent,
    PlanifiedDemandesComponent,
    AddCommentsComponent,
    ClientsWassaliComponent,
    CompteTransportorComponent,
    DisplayDemandesComponent,
    DetailsSocietyComponent,
    ContractSocietyComponent,
    EditProviderComponent,
    SendMailsComponent,
    AllProductsComponent,
    AllPalletsComponent,
    ImportBoxesProductsComponent,
    AddProviderProductsComponent,
    ImportProvidersComponent,
    EditTransportorSocietyComponent,
    AddPalletComponent,
    AddBoxComponent,
    AddCommandeComponent,
    AddBoxByProviderComponent,
    EditCommandeComponent,
    AddTeamComponent,
    TeamsListComponent,
    SocietiesListCofComponent,
    AddSocietyCofComponent,
    EditTeamComponent,
    EditSocietyCofComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(customNotifierOptions),

    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('token-cofat')
      }
    }),

    QRCodeModule,
    AvatarModule
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
