import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-send-mails',
  templateUrl: './send-mails.component.html',
  styleUrls: ['./send-mails.component.css']
})
export class SendMailsComponent implements OnInit {

  // isSaved: Boolean = true

  @Output() result = new EventEmitter<any>();
  @Input() cmds: any[];
  @Input() providerContacts: any[];
  @Input() transContacts: any[];

  form: FormGroup;

  constructor(private activeModal: NgbActiveModal,
    private cmdsService: CommandesService,
    private fb: FormBuilder,
    private teamsServices: TeamsService
  ) {
    this.form = fb.group({
      emails: fb.array([])
    })
  }


  ngOnInit(): void {
    this.addConnectedUserEmail();
  }
  get emails(): FormArray {
    return this.form.get("emails") as FormArray;
  }
  addEmail(): void {
    this.emails.push(this.fb.control('', Validators.email));
  }
  addConnectedUserEmail(): void {
    this.emails.push(this.fb.control(this.teamsServices.getEmail(), Validators.email));
  }
  deleteEmail(index: number) {
    if (this.form.value.emails.length > 1) {
      this.emails.removeAt(index);
    }
  }

  // mergeEmails() {
  //   let contacts: any[] = []
  //   this.providerContacts.forEach(contact => {
  //     if(contact.isChecked) {
  //       contacts.push(contact?.email)
  //     }
  //   });
  //   this.transContacts.forEach(contact => {
  //     if(contact.isChecked) {
  //       contacts.push(contact?.email)
  //     }
  //   });
  //   contacts.push(...this.form.value.emails)

  //   return contacts
  // }

  emailsNotEmpty(emails: any) {
    if (emails.length == 0)
      return false

    for (var i = 0; i < emails.length; i++) {
      if (emails[i] == null || emails[i] == "")
        return false
    }
    return true
  }
  sendMail() {
    var providerContacts = this.getEmails(this.providerContacts)
    // console.log(providerContacts)
    var transContacts = this.getEmails(this.transContacts)
    // console.log(transContacts)

    // var isValidate = this.emailsNotEmpty(this.form.value.emails)
    // if (!isValidate) {
    if (providerContacts?.length == 0 && transContacts?.length == 0 && !this.emailsNotEmpty(this.form.value.emails)) {
      // this.isSaved = false
      alert("Merci de sélectionner la liste des emails valides !");
    } else {
      // let contacts: any[] = this.mergeEmails()

      var data = {
        object: "Confirmation",
        cmds: this.cmds,

        providerContacts: providerContacts,
        transContacts: transContacts,
        others: this.form.value.emails
      }
      console.log("dataaaaaaaaaaaaa : ")
      console.log(data)
      this.cmdsService.sendMail(data).then(async (res: any) => {
        console.log("resssssssss : ")
        console.log(res)
        if (res && res.response) {
          console.log("ressssssssssssssssss");
          console.log(res)
          this.result.emit(true);
          this.activeModal.close();
        } else {
          alert(res.msg);
        }
      })
    }
  }
  checkValue(item: any) {
    if (item == null || (item != null && item == ""))
      return true
    else
      return false
  }
  getEmails(array: any[]) {
    let contacts: any[] = []
    array.forEach(contact => {
      if (contact.isChecked) {
        contacts.push(contact?.email)
      }
    });

    return contacts
  }

  closeModal() {
    this.result.emit(null);
    this.activeModal.dismiss();
  }

}
